exports.components = {
  "component---src-pages-booking-js": () => import("./../../../src/pages/booking.js" /* webpackChunkName: "component---src-pages-booking-js" */),
  "component---src-pages-confirm-and-pay-js": () => import("./../../../src/pages/confirm-and-pay.js" /* webpackChunkName: "component---src-pages-confirm-and-pay-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-order-confirmed-js": () => import("./../../../src/pages/order-confirmed.js" /* webpackChunkName: "component---src-pages-order-confirmed-js" */),
  "component---src-pages-service-booking-js": () => import("./../../../src/pages/service-booking.js" /* webpackChunkName: "component---src-pages-service-booking-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-templates-blog-list-js": () => import("./../../../src/templates/blog-list.js" /* webpackChunkName: "component---src-templates-blog-list-js" */),
  "component---src-templates-blog-posts-js": () => import("./../../../src/templates/blog-posts.js" /* webpackChunkName: "component---src-templates-blog-posts-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */)
}

